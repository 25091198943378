<template>
    <div>
        <div>
            <el-page-header @back="backMain" :content="'入库管理 > ' + title"></el-page-header>
            <el-form :model="model" :rules="rules" ref="inStockForm" class="detail_form" size="small" label-position="right" label-width="130px">
                <div class="form_item">
                    <div class="head_title">单据详情</div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="单据编号">
                                <el-input v-model="model.receiptNo" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="入库时间" prop="time">
                                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" v-model="model.time"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="入库分拣中心" prop="unitId">
                                <el-select v-model="model.unitId" @change="changeUnit">
                                    <el-option v-for="u in dUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="固废类别" prop="wasteTId">
                                <el-select v-model="model.wasteTId">
                                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item label="备注">
                                <el-input v-model="model.remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="form_item">
                    <div class="head_title">入库详情</div>
                    <el-row>
                        <jy-table :data="dataList">
                            <jy-table-column label="物资名称">
                                <template v-slot="scope">
                                    <el-autocomplete
                                        v-model="scope.row.cName"
                                        :fetch-suggestions="queryNameSearchAsync"
                                        :trigger-on-focus="false"
                                        valueKey="cName"
                                        placeholder="请输入物资名称"
                                        @select="item => handleSelect(item, scope.$index)"
                                    ></el-autocomplete>
                                </template>
                            </jy-table-column>
                            <jy-table-column label="编码">
                                <template v-slot="scope">
                                    <el-autocomplete
                                        v-model="scope.row.code"
                                        :fetch-suggestions="queryCodeSearchAsync"
                                        :trigger-on-focus="false"
                                        placeholder="请输入物资编码"
                                        valueKey="code"
                                        @select="item => handleSelect(item, scope.$index)"
                                    ></el-autocomplete>
                                </template>
                            </jy-table-column>
                            <jy-table-column label="自定义编码">
                                <template v-slot="scope">
                                    <el-autocomplete
                                        v-model="scope.row.cCode"
                                        :fetch-suggestions="queryCCodeSearchAsync"
                                        :trigger-on-focus="false"
                                        valueKey="cCode"
                                        placeholder="请输入自定义编码"
                                        @select="item => handleSelect(item, scope.$index)"
                                    ></el-autocomplete>
                                </template>
                            </jy-table-column>
                            <jy-table-column label="型号" prop="model"></jy-table-column>
                            <jy-table-column label="回收类别" prop="recycleTStr"></jy-table-column>
                            <jy-table-column label="单位">
                                <template><span>kg</span></template>
                            </jy-table-column>
                            <jy-table-column label="当前库存量" prop="cAmount"></jy-table-column>
                            <jy-table-column label="入库量">
                                <template v-slot="scope">
                                    <el-input v-model="scope.row.amount"></el-input>
                                </template>
                            </jy-table-column>
                            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
                        </jy-table>
                    </el-row>
                </div>
            </el-form>
            <div class="form_handle">
                <el-button type="primary" size="small" @click="makeSure">确定</el-button>
                <el-button size="small" @click="backMain">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            backMain() {
                this.$emit("switch");
            },
            changeUnit(key) {
                const unit = this.dUnitList.find(k => k.unitId === key);
                this.model.orgId = unit.orgId;
                this.model.mOrgId = unit.mOrgId;
                this.model.dataList = [];
            },
            makeSure() {
                const valideRows = this.dataList.filter(v => v.materialId).length;
                if (valideRows) {
                    this.$refs.inStockForm.validate(valide => {
                        if (valide) {
                            let url = "/stock/io/add";
                            if (this.model.ioStockId) {
                                url = "/stock/io/update";
                            }
                            this.$http
                                .post(url, {
                                    ...this.model,
                                    ioT: "1",
                                    detailList: this.dataList.map(v => {
                                        return {
                                            materialId: v.materialId,
                                            amount: v.amount
                                        };
                                    })
                                })
                                .then(res => {
                                    this.$message.success({
                                        message: res.msg
                                    });
                                    this.backMain();
                                });
                        }
                    });
                } else {
                    this.$message.warning({
                        message: "请填写入库详情"
                    });
                }
            },
            handleDelete(row, one, index) {
                if (this.dataList.length === 1) {
                    return;
                }
                this.dataList.splice(index, 1);
            },
            handleAdd() {
                this.dataList.push({
                    cName: "",
                    code: "",
                    cCode: "",
                    amount: ""
                });
            },
            async queryCodeSearchAsync(queryString, cb) {
                if (this.model.unitId) {
                    const result = await this.$http.post("/material/queryList", {
                        code: queryString
                    });
                    if (result.detail) {
                        cb(
                            result.detail.filter(v => {
                                return v.status == "1";
                            })
                        );
                    } else {
                        cb([]);
                    }
                } else {
                    this.$message.warning({
                        message: "请选择入库分拣中心"
                    });
                    cb([]);
                }
            },
            async queryCCodeSearchAsync(queryString, cb) {
                if (this.model.unitId) {
                    const result = await this.$http.post("/material/queryList", {
                        cCode: queryString
                    });
                    if (result.detail) {
                        cb(
                            result.detail.filter(v => {
                                return v.status == "1";
                            })
                        );
                    } else {
                        cb([]);
                    }
                } else {
                    this.$message.warning({
                        message: "请选择入库分拣中心"
                    });
                    cb([]);
                }
            },
            async queryNameSearchAsync(queryString, cb) {
                if (this.model.unitId) {
                    const result = await this.$http.post("/material/queryList", {
                        cName: queryString
                    });
                    if (result.detail) {
                        cb(
                            result.detail.filter(v => {
                                return v.status == "1";
                            })
                        );
                    } else {
                        cb([]);
                    }
                } else {
                    this.$message.warning({
                        message: "请选择入库分拣中心"
                    });
                    cb([]);
                }
            },
            handleSelect(item, index) {
                this.$set(this.dataList, index, {
                    ...item,
                    cAmount: 0,
                    recycleTStr: ""
                });
                this.$http
                    .post("/stock/batchQueryByMaterialIds", {
                        unitId: this.model.unitId,
                        materialIds: [item.materialId]
                    })
                    .then(res => {
                        const cur = res.detail[0];
                        this.dataList[index].cAmount = cur ? cur.amount : 0;
                    });
                this.$http.post("/recycleType/batchQueryByRecycleIds", item.recycleTId).then(res => {
                    this.dataList[index].recycleTStr = res.detail[0].cName;
                });
            },
            generateReceiptNo() {
                return "RK1" + this.$util.parseTime(new Date(), "yyyyMMddhhmmss");
            }
        },
        computed: {
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            dUnitList() {
                return this.$parent.dUnitList;
            }
        },
        async mounted() {
            if (this.$parent.options) {
                const op = this.$parent.options;
                this.model = {
                    receiptNo: op.receiptNo,
                    unitId: op.unitId,
                    time: op.time,
                    wasteTId: op.wasteTId,
                    remark: op.remark,
                    ioStockId: op.ioStockId
                };
                const materialRes = await this.$http.post("/stock/io/queryIODetailByIoStockId", this.model.ioStockId);
                this.dataList = materialRes.detail.map(v => {
                    return {
                        ...v,
                        ...v.material,
                        recycleTId: v.recycleType.recycleTId,
                        recycleTStr: v.recycleType.cName,
                        cAmount: 0
                    };
                });

                this.$http
                    .post("/stock/batchQueryByMaterialIds", {
                        unitId: this.model.unitId,
                        materialIds: this.dataList.map(v => v.materialId)
                    })
                    .then(res => {
                        const list = res.detail;
                        list.forEach(v => {
                            const one = this.dataList.find(m => m.materialId === v.materialId);
                            if (one) {
                                one.cAmount = v.amount;
                            }
                        });
                    });

                this.title = "编辑";
            } else {
                this.model.receiptNo = this.generateReceiptNo();
            }
        },
        data() {
            return {
                title: "新增",
                model: {
                    receiptNo: "",
                    unitId: "",
                    time: "",
                    wasteTId: "",
                    remark: ""
                },

                rules: {
                    time: [{ required: true, message: "选择入库时间", trigger: "change" }],
                    unitId: [{ required: true, message: "选择入库分拣中心", trigger: "change" }],
                    wasteTId: [{ required: true, message: "选择固废类别", trigger: "change" }]
                },

                dataList: [
                    {
                        cName: "",
                        code: "",
                        cCode: "",
                        amount: ""
                    }
                ],

                operateList: [
                    {
                        name: "增加",
                        icon: "el-icon-edit-outline",
                        fun: this.handleAdd,
                        isShow: () => {
                            return true;
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return true;
                        }
                    }
                ]
            };
        }
    };
</script>
<style lang="scss" scoped>
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }

    .form_handle {
        text-align: center;
    }
</style>
