<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="单据编号" prop="receiptNo">
                <el-input v-model="form.receiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="入库分拣中心" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in dUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteTId">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="回收类别" prop="recycleT">
                <div @click="selecrecycleTs">
                    <el-input placeholder="请选择" v-model="form.recycleTNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="物资名称" prop="materialNa">
                <el-input v-model="form.materialNa"></el-input>
            </jy-query-item>
            <jy-query-item label="制单人" prop="cUserNa">
                <el-input v-model="form.cUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="入库时间" prop="createT" :span="1.5">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesStockManageCenterInBoundList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesStockManageCenterInBoundReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesStockManageCenterInBoundInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesStockManageCenterInBoundExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/stock/io/export"
                        :params="form"
                        fileName="入库单"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="tableData">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="单据编号" prop="receiptNo" min-width="170"></jy-table-column>
                <jy-table-column label="入库时间" prop="time" min-width="160"></jy-table-column>
                <jy-table-column label="入库分拣中心" prop="unitId" min-width="130">
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="固废类别" prop="wasteTId" min-width="120">
                    <template v-slot="scope">{{ formatWast(scope.row.wasteTId) }}</template>
                </jy-table-column>
                <jy-table-column label="入库物资" show-overflow-tooltip min-width="120">
                    <template v-slot="scope">
                        {{ formatMaterial(scope.row.detailList) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="回收类别" show-overflow-tooltip min-width="120">
                    <template v-slot="scope">{{ formatrecycleTs(scope.row.detailList) }}</template>
                </jy-table-column>
                <jy-table-column label="制单人" prop="cUserNa" min-width="120"></jy-table-column>
                <jy-table-column label="备注" prop="remark"  min-width="200"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <recycle-types-tree ref="recycle" @addInstitutions="addRecycleType"></recycle-types-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import RecycleTypesTree from "@/components/pages/admin/common/dialog-tree/recycleTypes_tree.vue";
    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/stock/io/queryPage", {
                        ioT: "1",
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        timeStart: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                        timeEnd: this.$util.addTime(this.form.createT && this.form.createT[1]),
                        createT: undefined
                    })
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$emit("switch");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.recycleTNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addRecycleType(data) {
                this.form.recycleTNa = data.cName;
                this.form.recycleT = data.recycleTId;
            },
            // 选择机构树
            selecrecycleTs() {
                this.$refs.recycle.init();
            },
            handleDelete(row) {
                this.$confirm("是否删除该入库单，删除不可恢复?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/stock/io/deleteByIoStockId";
                        this.$http
                            .post(url, {
                                ioT: "1",
                                ioStockId: row.ioStockId
                            })
                            .then(res => {
                                this.$message({
                                    type: "success",
                                    message: res.msg
                                });
                                this.getList();
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            handleEdit(row) {
                this.$emit("switch", row);
            },
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleEdit,
                        isShow: () => {
                            return this.btnexist("wastesStockManageCenterInBoundUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesStockManageCenterInBoundDelete");
                        }
                    }
                ];
                this.operateList = l;
            },
            formatWast(id) {
                const w = this.wasteTypes.find(v => v.wasteTId === id);
                if (w) {
                    return w.cName;
                }
                return "-";
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatMaterial(list) {
                if (list && list.length) {
                    const mStrList = list.map(v => {
                        return v.material.cName + v.amount + "kg";
                    });
                    return mStrList.join(",");
                } else {
                    return "-";
                }
            },
            formatrecycleTs(list) {
                if (list && list.length) {
                    return list.map(v => v.material.recycleTStr).join(",");
                } else {
                    return "-";
                }
            }
        },
        mounted() {
            this.getList();
            this.setOperateList();
        },
        computed: {
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            dUnitList() {
                return this.$parent.dUnitList;
            }
        },
        data() {
            return {
                loading: false,
                form: {
                    receiptNo: "",
                    unitId: "",
                    wasteTId: "",
                    recycleT: "",
                    recycleTNa: '',
                    cUserNa: "",
                    materialNa: "",
                    createT: []
                },
                tableData: [],
                total: 0,

                pageIndex: 1,
                pageSize: 10,

                operateList: [],

                btnMenuId: "wastesStockManageCenterInBound"
            };
        },
        components: {
            ExportExcel,
            RecycleTypesTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
