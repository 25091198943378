<template>
    <div>
        <jy-dialog :modal="modal" title="选择回收类别" :visible.sync="dialogVisible" custom-class="dialog_tree" :width="global.dialogTreeWidth">
            <el-tree
                :default-checked-keys="defaultKeys"
                ref="institutions_tree"
                :show-checkbox="check"
                :props="defaultProps"
                :data="typeTreeData"
                accordion
                @node-click="save"
                node-key="orgId"
            ></el-tree>
            <div v-if="check" class="tree_check_select">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button type="primary" size="small" @click="save">确定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                defaultProps: {
                    children: "children",
                    label: "cName"
                },
                check: false,
                defaultKeys: [],

                typeTreeData: []
            };
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            modal: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            this.getRecycleTypes();
        },
        methods: {
            init(options) {
                this.dialogVisible = true;
                if (options) {
                    this.check = options.check;
                    this.defaultKeys = options.defaultKeys || [];
                } else {
                    this.check = false;
                }
            },

            getRecycleTypes() {
                this.$http.post("/recycleType/listTree").then(res => {
                    this.typeTreeData = [];
                    this.handleRecycleTypes(res.detail);
                });
            },
            handleRecycleTypes(menus, pmenu) {
                menus.forEach(v => {
                    if (v.isDel !== "1") {
                        const item = {
                            cName: v.cName,
                            recycleTId: v.recycleTId,
                            pRecycleTId: v.pRecycleTId
                        };
                        if (pmenu) {
                            if (!pmenu.children) {
                                pmenu.children = [];
                            }
                            pmenu.children.push(item);
                        } else {
                            this.typeTreeData.push(item);
                        }
                        if (v.children) {
                            this.handleRecycleTypes(v.children, item);
                        }
                    }
                });
            },

            save(data) {
                if (this.check) {
                    this.$emit("addInstitutions", this.$refs.institutions_tree.getCheckedNodes(true));
                    this.dialogVisible = false;
                } else {
                    this.$emit("addInstitutions", data);
                    this.dialogVisible = false;
                }
            }
        }
    };
</script>
<style scoped>
    .tree_check_select {
        text-align: center;
        margin-top: 20px;
    }
</style>
